@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.banner{
  background-image: url(./Images/landingimg.png);
  background-size: cover;
  background-position: center;
  height: 75vh;
}
.navbar-main{
  background-color: #e9e9c8;
}

@media not all and (min-width: 768px) {
  .toggle-nav{
    margin-top: -3.5rem;
    margin-left: auto;
  }
  .banner{
    background-image: url(./Images/landingimg1.jpg);
    height: 58vh;
  }
}
